<template>
  <v-row>
    <v-col cols="12">
      <general-card class="pa-5 d-flex justify-space-between align-center">
        <v-breadcrumbs :items="items"></v-breadcrumbs>
        <v-btn small depressed outlined color="#8cc73e" @click="editBtn">
          Edit User List
        </v-btn>
      </general-card>
    </v-col>
    <v-col cols="7">
      <general-card class="pa-5" v-if="single_user_list">
        <v-row>
          <v-col cols="3">
            <h6>Name:</h6>
          </v-col>
          <v-col>
            <p class="text-caption">{{ single_user_list.displayName }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <h6>Status:</h6>
          </v-col>
          <v-col>
            <p class="text-caption">{{ single_user_list.status }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3"><h6>Created Date:</h6></v-col>
          <v-col>
            <p class="text-caption">{{ single_user_list.created_at }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <h6>Update Date:</h6>
          </v-col>
          <v-col>
            <p class="text-caption">{{ single_user_list.updated_at }}</p>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            <h6>Duration of Users:</h6>
          </v-col>
          <v-col>
            <p class="text-caption">
              {{ single_user_list.membershipDurationDays }} days
            </p>
          </v-col>
        </v-row>

        <v-row v-if="single_user_list.description">
          <v-col cols="3">
            <h6>Description:</h6>
          </v-col>
          <v-col>
            <p class="text-caption">{{ single_user_list.description }}</p>
          </v-col>
        </v-row>

        <v-row v-if="single_user_list.urlRestriction">
          <v-col cols="3">
            <h6>User List Restricted Url:</h6>
          </v-col>
          <v-col>
            <p class="text-caption">
              {{ single_user_list.urlRestriction.url }}
            </p>
          </v-col>
        </v-row>

        <v-row v-if="single_user_list.urlRestriction">
          <v-col cols="3">
            <h6>Url Restriction Type:</h6>
          </v-col>
          <v-col>
            <p class="text-caption">
              {{ single_user_list.urlRestriction.restrictionType }}
            </p>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              v-if="
                single_user_list && single_user_list.status.trim() == 'CLOSED'
              "
              small
              depressed
              outlined
              color="#8cc73e"
              @click="openList"
              class="float-right"
            >
              Open User List
            </v-btn>

            <v-btn
              v-if="
                single_user_list && single_user_list.status.trim() == 'OPEN'
              "
              small
              depressed
              outlined
              color="#8cc73e"
              @click="closeList"
              class="float-right"
            >
              Close User List
            </v-btn>
          </v-col>
        </v-row>
      </general-card>
    </v-col>
    <v-col cols="5">
      <transition name="scroll-x-reverse-transition">
        <general-card class="pa-5" v-if="remarketing_tag">
          <v-row>
            <v-col cols="12"
              ><h5>Remarketing Tag:</h5>
              <p class="text-caption">
                Tag to host on Advertiser Page to add users to User List
              </p>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <p class="text-caption">{{ remarketing_tag[0].snippet }}</p>
            </v-col>
          </v-row>
        </general-card>
      </transition>
    </v-col>
  </v-row>
</template>
<script>
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    user_list_id: null,
    param_id: null,
    name: null,
    items: [
      {
        text: "User Lists",
        disabled: false,
        href: "#/tradedesk/user-lists",
      },
      {
        text: "Single User",
        disabled: true,
        href: "#",
      },
    ],
    error: null,
  }),
  computed: {
    single_user_list() {
      return this.$store.state.dsp.single_user_list;
    },
    remarketing_tag() {
      return this.$store.state.dsp.remarketing_tag;
    },
  },
  components: {
    GeneralCard,
  },
  async mounted() {
    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch(
        "dsp/getSingleUserList",
        this.$route.params.id
      );
      await this.$store.dispatch("dsp/getRemarketingTag");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error;
      this.$store.commit("makingApiRequest", false);
    }
  },

  methods: {
    editBtn() {
      this.$router.push({
        name: "update-user-list",
        params: this.$route.params.id,
      });
    },
    async openList() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/openUserList", this.$route.params.id);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
    async closeList() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/closeUserList", this.$route.params.id);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>
